<template>
  <div class="my-3">
    <!-- <b-container class="my-3" align="left" fluid> -->
    <b-button variant="outline-dark" @click="toggleDisplayform()">
      <span v-if="displayForm">Skjul </span>
      <span v-if="!displayForm">Vis </span>
      Søgeformular
    </b-button>
    <b-button @click="myPendingOrders" variant="outline-primary">Mine igangværende ordre
    </b-button>
    <b-button @click="allPendingOrders" variant="outline-primary">Alle igangværende ordre
    </b-button>
    <b-button @click="allPackingOrders" variant="outline-primary">Alle klar til pakning</b-button>
    <b-button @click="allFinishedOrders" variant="outline-primary">Alle Afsluttede ordre
    </b-button>
    <div v-if="displayForm">
      <b-collapse id="collapse-1" visible>
        <b-form ref="form" @submit.prevent="handleSubmit" @reset="onReset">
          <b-row class="my-3">
            <b-col lg="3">
              <b-form-group label="Firma navn:" label-for="company-name-input"
                invalid-feedback="Company name is required" label-class="font-weight-bold">
                <b-form-input placeholder="Firma navn" v-model="companyName"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <b-form-group label="Afsend fra:" invalid-feedback="Ship from is required" label-class="font-weight-bold">
                <b-form-radio-group size="" id="radio-group-1" v-model="locationSelected" :options="locationOptions"
                  name="ship-from"></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="3">
              <b-form-group label="Shipping type:" label-for="shippingtype-input"
                invalid-feedback="Shipping type is required" label-class="font-weight-bold">
                <b-form-radio-group id="radio-group-2" v-model="shippingTypeSelected" :options="shippingTypeOptions"
                  name="shipping-options"></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="my-3" lg="3">
              <b-form-group label="Produkt nr.:" label-for="product-number-input"
                invalid-feedback="Product number is required" label-class="font-weight-bold">
                <b-form-input placeholder="Produkt nr." v-model="productNumber"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="3">
              <b-form-group label="Land:" label-for="country-input" invalid-feedback="Country is required"
                label-class="font-weight-bold">
                <b-form-select v-model="countrySelected" :options="countryOptions"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="3">
              <b-form-group label="Shop type:" label-for="shop-type-input" invalid-feedback="Shop type is required"
                label-class="font-weight-bold">
                <b-form-radio-group id="radio-group-3" v-model="shopTypeSelected" :options="shopTypeOptions"
                  name="shopType-options"></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="my-3" lg="3">
              <b-form-group label="Ordre nr.:" label-for="order-number-input"
                invalid-feedback="Order number is required" label-class="font-weight-bold">
                <b-form-input placeholder="Ordre nr." v-model="orderNumber"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button-group>
            <b-button class="my-3" type="submit" variant="success">Søg</b-button>
            <b-button class="my-3" type="reset" variant="secondary">Nulstil</b-button>
          </b-button-group>
        </b-form>
      </b-collapse>
    </div>
    <!-- </b-container> -->
  </div>
</template>

<script>
import cookies from "js-cookie";

export default {
  emits: ["on-search"],
  data() {
    return {
      displayForm: true,
      domSessionId: "",
      fullname: "",
      username: "",
      queryParam: {},
      companynameState: null,
      productNumber: "",
      orderNumber: "",
      companyName: "",
      countrySelected: null,
      countryOptions: [
        { value: null, text: "Vælg et land" },
        { value: "dk", text: "Denmark" },
        { value: "ge", text: "Germany" },
      ],

      shopTypeSelected: null,
      shopTypeOptions: [
        { text: "classic", value: "classic" },
        { text: "Valggaven", value: "valggaven" },
        { text: "Andet", value: "Andet" },
      ],

      shippingTypeSelected: null,
      shippingTypeOptions: [
        { text: "direkte", value: "=1" },
        { text: "samlet", value: ">1" },
      ],

      locationSelected: null,
      locationOptions: [
        { text: "Nivå", value: "Nivå" },
        { text: "Kolding", value: "Kolding" },
        { text: "ASA", value: "ASA" },
        { text: "Copack", value: "Copack" },
      ],
    };
  },
  mounted() {

    this.domSessionId = cookies.get("DomSessionId");
  },
  methods: {
    toggleDisplayform() {
      this.displayForm = !this.displayForm
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.companynameState = valid;
      return valid;
    },
    handleSubmit() {
      //   Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //   Hide the modal manually
      this.onSearch();
      String.prototype.toProperCase = function (opt_lowerCaseTheRest) {
        return (opt_lowerCaseTheRest ? this.toLowerCase() : this)
          .replace(/(^|[\s\xA0])[^\s\xA0]/g, function (s) { return s.toUpperCase(); });
      };
    },
    myPendingOrders: function () {
      this.displayForm = false;
      this.onReset();
      this.queryParam.get = "my-picked";
      this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      this.$emit("on-search", this.createQueryString(this.queryParam));
      this.queryParam = {};
    },
    allPendingOrders: function () {
      this.displayForm = false;
      this.onReset();
      this.queryParam.get = "all-picked";
      this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      this.$emit("on-search", this.createQueryString(this.queryParam));
      this.queryParam = {};
    },
    allPackingOrders: function () {
      this.displayForm = false;
      this.onReset();
      this.queryParam.get = "all-pending-packing";
      this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      this.$emit("on-search", this.createQueryString(this.queryParam));
      this.queryParam = {};
    },
    allFinishedOrders: function () {
      this.displayForm = false;
      this.onReset();
      this.queryParam.get = "all-finished";
      this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      this.$emit("on-search", this.createQueryString(this.queryParam));
      this.queryParam = {};
    },
    onSearch: function () {
      this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      this.$emit("on-search", this.createQueryString(this.queryParam));
      this.queryParam = {};
    },
    onReset: function () {
      this.productNumber = null;
      this.orderNumber = null;
      this.companyName = null;
      this.countrySelected = null;
      this.shopTypeSelected = null;
      this.shippingTypeSelected = null;
      this.locationSelected = null;
      this.pendingOrders = null;
    },
    setQueryParams: function () {

      this.queryParam.productNumber = this.productNumber;
      this.queryParam.orderNumber = this.orderNumber;
      this.queryParam.companyName = this.companyName;
      this.queryParam.shippingCountry = this.countrySelected;
      this.queryParam.shopType = this.shopTypeSelected;
      this.queryParam.NumberOfRegistrations = this.shippingTypeSelected;
      this.queryParam.packingLocation = this.locationSelected;
    },
    createQueryString: function (queryParam) {

      console.log("doSearch");
      let queryString = "?";
      let clean = false;
      const keys = Object.keys(queryParam);
      keys.forEach((key) => {
        // console.log(`${key}: ${queryParam[key]}`);
        if (!(queryParam[key] === "" || queryParam[key] == null)) {
          // queryString += "&" + key + "=" + queryParam[key];
          queryString += key + "=" + queryParam[key] + "&";
          clean = true;
        }
      });

      if (clean) {
        queryString = queryString.substring(0, queryString.length - 1);
      }
      return queryString;
    },
  },
};
</script>

<style scoped>
.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}

>>>.custom-radio {
  float: left;
  margin-right: 8px;
}
</style>