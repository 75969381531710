<template>
  <div class="list_orders">
   <list-orders></list-orders>
  </div>
</template>

<script>
// @ is an alias to /src
// import PickOrders from '@/components/pickorders/PickOrders.vue'
import ListOrders from '../components/pickorders/ListOrders.vue'


export default {
  name: 'Orders',
  components: {
    ListOrders
  }
}
</script>
