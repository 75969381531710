<template>
  <div>
    <search-form v-on:on-search="doSearch" />

    <b-form>
      <b-row>
        <b-col lg="3">
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-button disabled variant="outline-secondary">{{ totalRows }} hits</b-button>
            </b-input-group-prepend>
            <b-form-input class="filterfield" id="filter-input" v-model="filter" type="search"
              placeholder="Skriv her for at søge i resultat"></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Nulstil</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form>
    <b-table class="my-4" :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
      @filtered="onFiltered" :select-mode="selectMode" ref="selectableTable" selectable small
      @row-selected="onRowSelected" responsive="true" head-variant="light">
      <template #cell(actions)="row">

        <b-button :variant="row.item.btncolor" @click="readPick(row.item, row.index, $event.target)" class="mr-1">
          <span v-if="row.item.pickingBy !== ''">
            {{ row.item.btntext }} -
            {{
              row.item.pickingBy.substring(0, row.item.pickingBy.indexOf("@"))
            }}
          </span>
          <span v-else>{{ row.item.btntext }}</span>
        </b-button>

      </template>
    </b-table>

    <b-spinner v-if="isLoading" style="position: fixed; left: 50%; top: 50%; width: 4rem; height: 4rem"></b-spinner>
  </div>
</template>

<script>
import SearchForm from "./SearchForm.vue";
import apis from "../../apis/Apis";
import cookies from "js-cookie";

export default {
  components: {
    SearchForm,
  },
  data() {
    return {
      domSessionId: "",
      selectMode: "single",
      isLoading: null,
      username: localStorage.username,
      fullname: localStorage.fullname,
      totalRows: 0,
      currentPage: 1,
      perPage: 1000,
      sortBy: "orderDate",
      sortDesc: false,
      filter: null,
      filterOn: [],
      fields: [
        // { key: "id", sortable: true },
        { key: "status", sortable: true },
        { key: "orderNumber", sortable: true, label: "Ordre nr." },
        { key: "shopType", sortable: true, label: "Type" },
        { key: "packingLocation", sortable: true, label: "Lokation" },
        { key: "carrier", sortable: true, label: "Transportør" },
        { key: "deliveryDate", sortable: true, label: "Leverings dato" },
        { key: "companyName", sortable: true, label: "Firma" },
        { key: "locationName", sortable: true, label: "Adresse" },
        { key: "numberOfRegistrations", sortable: true, label: "Antal" },
        { key: "countryCode", sortable: true, label: "Land" },
        { key: "actions", label: "Handling" },
      ],
      items: [],
      selected: [],
    };
  },

  mounted() {
    this.domSessionId = cookies.get("DomSessionId");
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  methods: {
    doSearch(queryString) {
      this.loadShipment(queryString);
    },

    loadShipment: async function (queryString) {

      this.isLoading = true;
      try {
        const response = await apis.loadShipment(
          queryString,
          this.domSessionId
        );

        this.items = response.data;

        for (var i = 0; i < this.items.length; i++) {
          console.log("items", i, this.items[i])
          if (this.items[i].pickingBy !== "") {

            if (this.items[i].pickingStatus === "replenish") {
              this.items[i].btncolor = "warning"
              this.items[i].btntext = "Gen opfyld"
            } else if (this.items[i].pickingStatus === "paused") {
              this.items[i].btntext = "På pause"
              this.items[i].btncolor = "success"
            } else if (this.items[i].pickingStatus === "pendingfinish") {
              this.items[i].btntext = "Til pakning"
              this.items[i].btncolor = "primary"
            } else if (this.items[i].status == "ready" && this.items[i].pickingStatus == "finished") {
              this.items[i].btntext = "Vis"
              this.items[i].btncolor = "primary"
              this.items[i].status = "Afsluttet"
            } else {
              this.items[i].btntext = "Vis"
              this.items[i].btncolor = "success"
            }
          } else {
            this.items[i].btntext = "Vis"
            this.items[i].btncolor = "success"
          }

          if (this.items[i].status == "") {
            this.items[i].status = "ingen status"
          } else if (this.items[i].status == "ready" && this.items[i].pickingStatus == "") {
            this.items[i].status = "Klar til pluk"
          // } else if (this.items[i].status == "ready" && this.items[i].pickingStatus != "") {
          //   this.items[i].status = "Plukning"
          } else if (this.items[i].status == "pending") {
            this.items[i].status = "Afventer"
          } else if (this.items[i].status == "shipped") {
            this.items[i].status = "Afsendt"
          } else if (this.items[i].status == "closed") {
            this.items[i].status = "Afsluttet"
          }

        }



        this.totalRows = response.data.length;
        this.isLoading = false;

        console.log(this.items)
      } catch (error) {
        console.log(error);
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selected = items;
    },

    isUserPicking: async function (item) {
      if (item.pickingStatus === "started") {
        this.startPick(item);
      } else {
        this.isLoading = true;
        try {
          const response = await apis.loadShipment(this.domSessionId);
          if (response.data.length >= 1) {
            this.$bvModal.msgBoxOk(
              "Du arbejder allerede på en anden ordre. Pause den før du starter på en ny"
            );
          } else {
            this.startPick(item);
          }
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },

    startPick(item) {
      // eslint-disable-next-line no-debugger
      debugger
      localStorage.currentPickId = item.id;
      localStorage.currentPickAction = "started";
      localStorage.readMode = false;
      this.$router.push({
        path: "/pickorder",
      });
    },
    readPick(item) {
      localStorage.currentPickId = item.id;
      localStorage.readMode = true;
      this.$router.push("/pickorder");
    },
    showXhrResult(response) {
      console.log("#SS response", response);
      console.log("#SS request", response.request);
      console.log("#SS Status", response.status);
      console.log("#SS StatusText", response.statusText);
      console.log("#SS Headers", response.headers);
      console.log("#SS Config", response.config);
      console.log("#SS response.data", response.data);
    },
  },
};
</script>

    

<style scoped>
::v-deep .sr-only {
  display: none !important;
}
</style>

